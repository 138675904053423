import { CreateElement, VNode } from 'vue';
import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { AuthStoreMethods } from '../../store/modules/auth/AuthStore';

@Component({
    metaInfo: {
        title: 'Logout',
    },
    name: 'Logout',
})
export default class Logout extends Vue {

    // Méthode de logout.
    @Action(AuthStoreMethods.LOGOUT)
    public logout: () => Promise<void>;

    public created(): void {
        const homePath = '/';
        this.logout().finally(() => {
            window.location.replace(homePath);
        });
    }

    // Render functions are an alternative to templates.
    public render(h: CreateElement, context: any): VNode {
        return null;
    }
}
